import React from 'react';
import { navigate } from 'gatsby';
import Crossword from '../../assets/svg/crossword.svg';
import Jigsaw from '../../assets/svg/jigsaw.svg';
import WordSearch from '../../assets/svg/wordsearch.svg';

import { Link } from 'gatsby';

const ChapterActivityCard = (props) => {
  const { chapter, language } = props;
  const label = language === 'eng' ? 'Activity Games' : 'Juegos de actividades';

  return (
    <div className="book-chapter-container">
      <div className="p-3">
        <p className="fnt-evolventa bold book-activity-title fnt-black">{label}</p>
      </div>
      <div className="chapters-activities pl-3 pr-3">
        <div
          onClick={() => {
            navigate(chapter.game2);
          }}
          onKeyDown={() => {}}
          className="chapter-activity-container wordsearch"
        >
          <img src={WordSearch} width="100%" />
        </div>
        <div
          onClick={() => {
            navigate(chapter.game1);
          }}
          onKeyDown={() => {}}
          className="chapter-activity-container crossword"
        >
          <img src={Crossword} width="100%" />
        </div>
        <div
          onClick={() => {
            navigate(chapter.game3);
          }}
          onKeyDown={() => {}}
          className="chapter-activity-container jigsaw"
        >
          <img src={Jigsaw} width="100%" />
        </div>
      </div>
    </div>
  );
};

export default ChapterActivityCard;
