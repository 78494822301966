import React, { useState } from 'react';
import AudioPlayer from '../audioPlayer';

const BookMediaCard = (props) => {
  const { book } = props;

  return (
    <div className="book-media-container">
      <AudioPlayer
        tracks={book.chapters}
        trackGroup={book.id}
        defaultPlay={true}
        initialTrackIndex={0}
        bookTitle={book.title}
      />
    </div>
  );
};

export default BookMediaCard;
