import React from 'react';
import { useState, useEffect } from 'react';
import BreadCrumb from '../quiz/breadcrumb';
import carret from '../../assets/png/carretdown.png';
import { getQuizQuestionsByChapterId, postQuiz, resetPostedQuiz } from '../../actions/quizActions';
import { useDispatch, useSelector } from 'react-redux';
import { RESET_CURRENT_CHAPTER, RESET_QUIZ_DATA, RESET_QUIZ_QUESTIONS } from '../../store/reducer';
import Spinner from 'react-spinkit';
import axios from 'axios';
import { REMOTE_URL } from '../../common/api';

const tran = {
  eng: {
    chapterQuiz: 'Chapter Quiz',
    rightOption: 'Choose the right option',
    start: 'Start Quiz',
    incorrect: 'Incorrect',
    correct: 'Correct',
    question: 'Question',
    review: 'Review Questions',
    submit: 'Submit',
    next: 'Next',
    score: 'Your score',
    restart: 'Restart Quiz',
    view: 'View Questions',
    result: 'Result',
  },
  esp: {
    chapterQuiz: 'Examen del capítulo',
    rightOption: 'Elige la opcion correcta',
    start: 'Iniciar prueba',
    incorrect: 'Iniciar prueba',
    correct: 'Correcto',
    question: 'Pregunta',
    review: 'Preguntas de revisión',
    submit: 'Entregar',
    next: 'Próximo',
    score: 'Tu puntuación',
    restart: 'Reiniciar prueba',
    view: 'Ver preguntas',
    result: 'Resultado',
  },
};

const ResultScreen = ({
  quizSummary,
  result,
  handleRestartQuiz,
  DisplayQuestionAnswer,
  setSubmitLoading,
  language,
}) => {
  const [viewQuestion, setViewQuestion] = useState(false);

  setSubmitLoading(false);

  let totalQuestion = quizSummary.length;
  let correctAnswer = quizSummary.length && quizSummary.filter((obj) => obj.answers === 1);

  return (
    <>
      <div className="quiz-result">
        <div className="quiz-result__title">{tran[language].result}</div>
        <div className="quiz-result__completed">
          <div className="quiz-result__completed__outer">
            <div className="quiz-result__completed__inner">
              <div className="quiz-result__completed__border">
                <div>
                  <div className="quiz-result__value">
                    {correctAnswer.length}/{totalQuestion}
                  </div>
                  <div className="quiz-result__label">{tran[language].score}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="display-flex pt-4">
          <div>
            <button
              className="bg-prime no-border fnt-white fnt-evolventa fnt-14 bold pt-2 pb-2 pl-4 pr-4"
              onClick={handleRestartQuiz}
            >
              {tran[language].restart}
            </button>
          </div>
          <div className="pl-4 view-question">
            <button
              className="bg-prime no-border fnt-white fnt-evolventa fnt-14 bold pt-2 pb-2 pl-4 pr-4"
              onClick={() => setViewQuestion(true)}
            >
              {tran[language].view}
            </button>
          </div>
        </div>
      </div>
      {viewQuestion && DisplayQuestionAnswer()}
    </>
  );
};

const ChapterQuizCard = ({ chapterId, language }) => {
  const dispatch = useDispatch();

  const [showResults, setShowResults] = useState(false);
  const [questionNumber, setQuestionNumber] = useState(0);
  const [quizSummary, setQuizSummary] = useState([]);
  const [review, setReview] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [postData, setPostData] = useState({
    quiz_post_id: '',
    result: '',
    book_id: '',
    chapter_id: '',
    token: '',
  });

  const currentChapter = useSelector((state) => state.currentChapter);
  const currentQuizQuestions = useSelector((state) => state.currentQuizQuestions);
  const auth = useSelector((state) => state.auth);
  const postedResult = useSelector((state) => state.currentQuiz.posted);

  const handleStartQuiz = () => {
    dispatch(getQuizQuestionsByChapterId(currentChapter.chapter_id, language));
    setShowResults(true);
  };

  const handleNext = () => {
    setQuestionNumber((prevQuestion) => prevQuestion + 1);
  };

  const handleAnswerChange = (e) => {
    let arr = [...quizSummary];
    let obj = { questionNumber: '', answers: 0, userSelection: null };

    // Finds if question is already answered
    let duplicateIndex = arr.findIndex((item) => item.questionNumber === questionNumber);

    obj.questionNumber = questionNumber;
    obj.answers = currentQuizQuestions?.questions[questionNumber]?.answers[e.target.id]?.option.length;
    obj.userSelection = e.target.id;

    if (duplicateIndex === -1) {
      arr.push(obj);
    } else {
      arr[duplicateIndex] = obj;
    }
    setQuizSummary(arr);
  };

  const questionLength = currentQuizQuestions?.questions?.length && currentQuizQuestions.questions.length;

  // Find the checked items in the answer's checbox
  const handleUserChecked = (index) => {
    const value = quizSummary.find((obj) => obj.questionNumber === questionNumber);
    if (value) {
      if (index.toString() === value.userSelection) {
        return true;
      }
    }

    return false;
  };

  const handleAllCheckedAnswer = (answerIndex, questionIndex) => {
    const value = quizSummary[questionIndex];
    const result = parseInt(value.userSelection) === answerIndex;

    return result;
  };

  const isAnsweredInputCorrect = (questionIndex) => {
    const value = quizSummary[questionIndex];
    const result = value.answers;

    return result;
  };

  //Handle submit
  const handleSubmit = () => {
    let answers = quizSummary.map((item) => {
      return item['answers'];
    });
    let total = 0;
    if (answers.length) {
      for (let i = 0; i < answers.length; i++) {
        total += answers[i];
      }
    }
    total /= quizSummary.length;
    total *= 100;
    let result = total.toFixed(2);
    let data = { ...postData };
    data.result = result;
    setPostData({ ...postData, result: result });
    setSubmitted(true);
    setSubmitLoading(true);
    dispatch(postQuiz(data, auth.userId, language));
  };

  //Validation and preparing data before submitting
  const handleValidation = () => {
    if (quizSummary.length > questionNumber) {
      return true;
    } else {
      return false;
    }
  };

  //Check wheather answer question is answered or not.
  const handleAnsweredQuestion = (questionNumber) => {
    const question = quizSummary.length && quizSummary.find((item) => item.questionNumber === questionNumber);
    if (question) {
      return true;
    } else {
      return false;
    }
  };

  const handleReviewCheck = (index) => {
    let arr = [...review];
    let result = arr.findIndex((item) => item === index);

    if (result === -1) {
      return false;
    } else {
      return true;
    }
  };

  const checkLastQuestion = () => {
    if (questionNumber + 1 < currentQuizQuestions?.questions?.length) {
      return false;
    } else {
      return true;
    }
  };

  const handleReview = () => {
    if (!checkLastQuestion()) {
      let result = handleReviewCheck(questionNumber);
      if (!result) {
        setReview([...review, questionNumber]);
        handleNext();
      }
    }
  };

  const handleRestartQuiz = () => {
    handleStartQuiz();
    dispatch(resetPostedQuiz());
    setQuizSummary([]);
    setQuestionNumber(0);
    setReview([]);
    setSubmitted(false);
    setPostData({
      ...postData,
      result: '',
    });
  };
  const getChapterId = (params) => {
    const lang = language === 'eng' ? 'en' : 'es';
    axios
      .get(`${REMOTE_URL}/wp-json/custom/v2/cpt/get_chapter?params=${params}&lang=${lang}`)
      .then((response) => {
        setPostData({
          quiz_post_id: response?.data?.lms_quiz_id,
          result: '',
          book_id: response?.data?.book_id,
          chapter_id: response?.data?.chapter_id,
          token: auth.token,
        });
        dispatch({
          type: 'GET_CHAPTER_ID_FROM_PARAMS',
          payload: response.data,
        });
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getChapterId(chapterId.substring(0, 2) + '-' + chapterId.substring(2, 4));

    return () => {
      dispatch({ type: RESET_QUIZ_QUESTIONS });
      dispatch({ type: RESET_CURRENT_CHAPTER });
      dispatch({ type: RESET_QUIZ_DATA });
    };
  }, [chapterId, language]);

  const DisplayQuestionAnswer = () => {
    return QuestionsWithAnswer();
  };

  const QuestionsWithAnswer = () => (
    <div className="pl-3 pt-4">
      <div className="ans-div">
        <div className="qn-ans-container review">
          {currentQuizQuestions?.questions?.length &&
            currentQuizQuestions.questions.map((item, index) => (
              <div className="qn-ans-inner" key={item.question}>
                <div>
                  <p className="qns-number">
                    {index + 1}. {tran[language].question}
                  </p>
                  <p className="qns">{item.question}</p>
                </div>
                <div className="ans checked">
                  {item.answers.map((choice, choiceIndex) => (
                    <label className="label-container" key={choiceIndex}>
                      {choice.answer}
                      <input
                        type="radio"
                        name={`radio-${index}`}
                        disabled
                        id={choiceIndex}
                        defaultChecked={handleAllCheckedAnswer(choiceIndex, index)}
                      />
                      <span className="checkmark"></span>
                    </label>
                  ))}
                </div>
                <div className="qn-ans-response">
                  {isAnsweredInputCorrect(index) === 1 ? tran[language].correct : tran[language].incorrect}
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
    // Checked ko issue milauna baki xa.
  );

  const Questions = () => (
    <>
      {currentQuizQuestions.questions.length === 0 ? (
        <Spinner name="circle" color="black" size={50} />
      ) : (
        <div className="pl-3 pt-4">
          <BreadCrumb
            list={currentQuizQuestions?.questions?.length && currentQuizQuestions.questions}
            current={questionNumber}
            setQuestionNumber={setQuestionNumber}
            quizSummary={quizSummary}
            handleAnsweredQuestion={handleAnsweredQuestion}
            handleReviewCheck={handleReviewCheck}
            language={language}
          />

          {!handleAnsweredQuestion(questionNumber) && !handleReviewCheck(questionNumber) && !checkLastQuestion() && (
            <div className="rvw-txt">
              <a className="fnt-evolventa fnt-weight-700 fnt-primary" onClick={handleReview}>
                {tran[language].review}
              </a>
            </div>
          )}
          <div className="ans-div">
            <div className="qn-ans-container">
              <div>
                <p className="qns-number">
                  {questionNumber + 1}. {tran[language].question}
                </p>
                <p className="qns">
                  {currentQuizQuestions?.questions?.length && currentQuizQuestions.questions[questionNumber]?.question}
                </p>
              </div>

              <div className="ans">
                {currentQuizQuestions?.questions?.length &&
                  currentQuizQuestions.questions[questionNumber]?.answers.map((choice, choiceIndex) => (
                    <label className="label-container" key={choiceIndex}>
                      {choice.answer}
                      <input
                        type="radio"
                        name="radio"
                        id={choiceIndex}
                        value={choice.option.length}
                        onChange={handleAnswerChange}
                        checked={handleUserChecked(choiceIndex)}
                      />
                      <span className="checkmark"></span>
                    </label>
                  ))}
                {questionNumber === questionLength - 1 ? (
                  <button
                    className={`${handleValidation() || !submitted ? 'btn-next' : 'btn-next disabled'}`}
                    disabled={!handleValidation() || submitted}
                    onClick={handleSubmit}
                  >
                    {tran[language].submit}
                    <span>
                      <img src={carret} />
                    </span>
                  </button>
                ) : (
                  <button
                    className={`${handleAnsweredQuestion(questionNumber) ? 'btn-next' : 'btn-next disabled'}`}
                    disabled={!handleAnsweredQuestion(questionNumber)}
                    onClick={handleNext}
                  >
                    {tran[language].next}
                    <span>
                      <img src={carret} />
                    </span>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );

  return (
    <div className={`${submitLoading ? 'book-chapter-container loading' : 'book-chapter-container'}`}>
      <div className="p-3">
        <p className="fnt-evolventa bold book-activity-title fnt-black">{tran[language].chapterQuiz}</p>
      </div>
      {submitLoading && (
        <div className="quiz-spinner">
          <Spinner name="circle" color="white" size={50} />
        </div>
      )}
      {postedResult?.length ? (
        <ResultScreen
          quizSummary={quizSummary}
          result={postData.result}
          handleRestartQuiz={handleRestartQuiz}
          DisplayQuestionAnswer={DisplayQuestionAnswer}
          postedResult={postedResult}
          setSubmitLoading={setSubmitLoading}
          language={language}
        />
      ) : (
        <>
          <div className="chapters-quiz-title pl-3 pr-3 dsp-flx flx-col">
            {currentQuizQuestions.quiz_description.length ? (
              <p className="fnt-poppins fnt-14 fnt-secondary">{currentQuizQuestions.quiz_description}</p>
            ) : (
              <></>
            )}
            <p className="fnt-poppins fnt-14 fnt-secondary">{tran[language].rightOption}</p>
          </div>
          {!showResults && (
            <div className="pl-3 pt-4">
              {!currentChapter.chapter_id ? (
                <Spinner name="circle" color="black" size={50} />
              ) : (
                <button
                  className="bg-prime no-border fnt-white fnt-evolventa fnt-14 bold pt-2 pb-2 pl-4 pr-4"
                  onClick={handleStartQuiz}
                >
                  {tran[language].start}
                </button>
              )}
            </div>
          )}
          {showResults ? <Questions /> : null}
        </>
      )}
    </div>
  );
};

export default React.memo(ChapterQuizCard);
