import React from 'react';
import AuthStateProvider from '../components/authStateProvider';
import Header from '../components/header/header';
import MainContainer from '../components/chaptersPage/mainContainer';
import Footer from '../components/footer';
import SEO from '../components/SEO';

export default function Chapters(props) {
  const { translatedUrl } = props.pageContext;

  return (
    <AuthStateProvider authRequired {...props}>
      <SEO title={`${props.pageContext.chapter.title}`} />
      <Header show={true} translatedUrl={translatedUrl} />
      <MainContainer chapter={props.pageContext.chapter} book={props.pageContext.book} />
      <Footer variant="post-login-footer" />
    </AuthStateProvider>
  );
}
