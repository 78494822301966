import React from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import { navigate } from 'gatsby-link';

const BookDescriptionCard = (props) => {
  const { chapter, book } = props;

  return (
    <>
      <div className="chapter-back" onClick={() => navigate(-1)}>
        <FaArrowLeft color="#fc650a" />
        <span>Back</span>
      </div>
      <div className="chapter-description-container">
        <div className="p-3 chapter-img-container">
          <div className="border-rd-16">
            <img src={chapter.cover} alt="" className="chapter-card-img" />
          </div>
        </div>
        <div className="p-3 dsp-flx flx-col justify-center chapter-desc-container">
          <p className="fnt-poppins fnt-r-16 fnt-weight-600 fnt-dark-d m-0">{chapter.chapter || ''}</p>
          <p className="fnt-evolventa book-title-text fnt-black bold">{chapter.title}</p>
          <p className="fnt-poppins fnt-14 fnt-secondary">{book.description || ''}</p>
        </div>
      </div>
    </>
  );
};

export default BookDescriptionCard;
