import React from 'react';
import './styles.scss';

/**
 *
 * @param {list} props
 */
const BreadCrumb = (props) => {
  const { list, current, setQuestionNumber, handleAnsweredQuestion, handleReviewCheck, language } = props;

  return (
    <div className="color-code-wrap">
      <div className="bread-holder">
        {list.length &&
          list.map((item, index) => (
            <div
              key={`bread-${index}`}
              className={`bread-div ${current === index ? 'bread-current' : ''} ${
                handleReviewCheck(index) ? 'bread-review' : ''
              } ${handleAnsweredQuestion(index) ? 'bread-answered' : ''}`}
              onClick={() => {
                (handleAnsweredQuestion(index - 1) || index === 0 || handleReviewCheck(index - 1)) &&
                  setQuestionNumber(index);
              }}
            >
              <p>{index + 1}</p>
            </div>
          ))}
      </div>
      <div className="color-code">
        <div className="color-code__item">
          <div className="color-code__palette ans"></div>
          <span>{language === 'eng' ? 'Answered' : 'Respondido'}</span>
        </div>
        <div className="color-code__item">
          <div className="color-code__palette rev"></div>
          <span>{language === 'eng' ? 'Review' : 'Revisar'}</span>
        </div>
      </div>
    </div>
  );
};
export default BreadCrumb;
