import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { omit } from 'lodash';
import {
  RESET_CURRENT_CHAPTER,
  RESET_QUIZ_QUESTIONS,
  TYPE_SET_PLAYLIST,
  TYPE_TOGGLE_MOBILE_PLAYER,
} from '../../store/reducer';
import ChapterDescriptionCard from './chapterDescriptionCard';
import ChapterQuizCard from './chapterCard';
import ChapterActivityCard from './chapterActivities';
import ChapterMediaCard from './chapterMediaCard';
import ExpandableContainer from '../expandableContainer';
import './style.scss';
// import { getQuiz } from "../../actions/quizActions";

const MobileMediaController = ({ chapter, book }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    var index = 0;
    book.chapters.forEach((b, ind) => {
      if (b.id === chapter.id) {
        index = ind;
      }
    });
    dispatch({
      type: TYPE_SET_PLAYLIST,
      payload: {
        playlist: book.chapters,
        track: chapter.id,
        trackGroup: book.id,
        trackIndex: index,
        bookTitle: book.title,
        book: omit(book, 'chapters'),
      },
    });
    dispatch({ type: TYPE_TOGGLE_MOBILE_PLAYER, payload: true });
    // dispatch(getQuiz(chapter.quiz_id));
  }, []);

  return <></>;
};

const MainContainer = (props) => {
  const { chapter, book } = props;
  const state = useSelector((state) => state);
  const { show, interrupt } = state.miniPlayer;
  const showMobilePlayer = state.mobilePlayer.show;
  const language = state.language;

  const chapterId = chapter.id;

  return (
    <ExpandableContainer expand={(show && !interrupt) || showMobilePlayer} className="bg-grey">
      <div className="books-page-container bg-grey">
        <div className="books-a">
          <ChapterDescriptionCard chapter={chapter} book={book} />
          <ChapterQuizCard chapterId={chapterId} language={language} />
          <ChapterActivityCard chapter={chapter} language={language} />
        </div>
        <div className="books-b hide-mobile">
          <ChapterMediaCard book={book} />
          {/* This is the audio player controller */}
        </div>
        <div className="hide-desktop">
          <MobileMediaController chapter={chapter} book={book} />
        </div>
      </div>
    </ExpandableContainer>
  );
};

export default MainContainer;
